import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Hero-Main" }) {
					localFile {
						publicURL
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: file(relativePath: { eq: "icon.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://www.thebackcare.clinic",
		name: "The Back Care Clinic",
		legalName: "The Back Care Clinic",
		description:
			"I can help by offering effective education, support and rehabilitative movement for people suffering with persistent or reoccurring back pain.",
		url: "https://www.thebackcare.clinic",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "London",
				sameAs: "https://en.wikipedia.org/wiki/London",
			},
		],
		email: "carol@thebackcare.clinic",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		foundingLocation: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/London",
		},
		slogan: "Together, we’ve got your back",
		telephone: "+447811388339",
		address: {
			"@type": "PostalAddress",
			addressLocality: "London",
			addressRegion: "Essex",
			postalCode: "ME8 0HA",
			streetAddress: "315 Maidstone Rd, Rainham, Gillingham",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "51.3555454",
			longitude: "0.5895298",
		},
		hasMap: "https://goo.gl/maps/GoeZWwsAwPVPekBLA",
		openingHours: "Tu 10:00-19:00",
		openingHours: "We 09:30-19:00",
		openingHours: "Fr 10:00-18:00",
		openingHours: "Sa 09:00-16:00",

		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "19:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:30",
			},

			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:00",
				dayOfWeek: "http://schema.org/Saturday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Carol Seaborne",
			id: "https://www.thebackcare.clinic/about#carol",
			url: "https://www.thebackcare.clinic/about",
			description:
				"Hi, I’m Carol Seaborne, and I qualified as a Level 5 Soft Tissue Therapist with the London School of Sports Massage in 2018.",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: `${reviewCount}`,
			url: "https://www.thebackcare.clinic",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.thebackcare.clinic/contact-me",
			url: "https://www.thebackcare.clinic/contact-me",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "The Back Care Clinic",
			description:
				"I can help by offering effective education, support and rehabilitative movement for people suffering with persistent or reoccurring back pain.",
			telephone: "+447811388339",
			contactType: "Contact Page",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "London",
					sameAs: "https://en.wikipedia.org/wiki/London",
				},
			],
		},
		logo: `https://back-care-clinic.rjmdigital.net/wp-content/uploads/2023/06/The-Back-Care-Clinic-Master-Icon.png`,
		image: `https://back-care-clinic.rjmdigital.net/wp-content/uploads/2023/06/0000304-Edit-scaled-e1687770974702.jpg`,
		sameAs: [
			"https://www.linkedin.com/in/carol-seaborne-813a09199/",
			"https://www.facebook.com/bodygoodtherapies",
			"https://www.instagram.com/the.back.care.clinic/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;

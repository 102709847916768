import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { MdMovie, MdOutlineClear } from "react-icons/md";
import ReactPlayer from "react-player";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import uuid4 from "uuid4";
import { BgImage } from "gbimage-bridge";
import LazyLoad from "react-lazyload";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "home" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        homePageFieldGroups {
          homePageBannerSection {
            bannerDescription
            heading
            videoButtonLabel
            videoPopupHeading
            cta {
              target
              title
              url
            }
            bannerVideo {
              mediaItemUrl
            }
            bannerImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageFaqSection {
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            faq {
              ... on WpFaq {
                id
                faqFields {
                  answer
                  page
                  question
                }
              }
            }
          }
          homePageStartYourJourneySection {
            heading
            cta {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageTestimonialSection {
            heading
            bottomNote
            googleReviewIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 88
                  )
                }
              }
            }
            googleReviewLink {
              title
              url
              target
            }
            testimonials {
              ... on WpTestimonial {
                id
                title
                testimonialsFields {
                  reviewSection {
                    description
                    problem
                    videoModalHeading
                    videoButtonText
                    video {
                      mediaItemUrl
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
                uri
              }
            }
          }
          homePageTextOnlySection {
            heading
            paragraph
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageTwoColumnLeftImageSectionWithCta {
            description
            heading
            cta {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageTwoColumnLeftImageSectionWithSectionTitle {
            description
            heading
            sectionTitle
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta {
              target
              title
              url
            }
          }
          homePageTwoColumnRightImageSection {
            heading
            description
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageTwoColumnRightImageSectionWithCta {
            heading
            description
            cta {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          homePageWorkTogetherSection {
            heading
            cta {
              target
              title
              url
            }
            backgroundimage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            items {
              description
              heading
              image {
                altText
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 48
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);
  const [showTestimonialVideo, setShowTestimonialVideo] = useState(false);
  const [testimonialVideo, setTestimonialVideo] = useState({
    heading: "",
    video: "",
  });

  const handleShowTestimonialVideo = (heading, videoUrl) => {
    setTestimonialVideo({
      heading: heading,
      video: videoUrl,
    });
    setShowTestimonialVideo(true);
  };

  const handleCloseTestimonialVideo = () => {
    setTestimonialVideo("");
    setShowTestimonialVideo(false);
  };

  const {
    pageData: { seoFieldGroups, homePageFieldGroups },
  } = data;

  const {
    homePageBannerSection,
    homePageFaqSection,
    homePageStartYourJourneySection,
    homePageTestimonialSection,
    homePageTextOnlySection,
    homePageTwoColumnLeftImageSectionWithCta,
    homePageTwoColumnLeftImageSectionWithSectionTitle,
    homePageTwoColumnRightImageSection,
    homePageTwoColumnRightImageSectionWithCta,
    homePageWorkTogetherSection,
  } = homePageFieldGroups;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          <Modal
            style={{ zIndex: 99999 }}
            size="lg"
            show={showTestimonialVideo}
            onHide={handleCloseTestimonialVideo}
            centered
          >
            <Modal.Body
              style={{ borderRadius: "8px" }}
              className="bg-white"
              closeButton
            >
              <div
                style={{ borderRadius: "8px" }}
                className="   text-center position-relative"
              >
                <div className="position-absolute  end-0 top-0 p-lg-2">
                  <MdOutlineClear
                    style={{ cursor: "pointer" }}
                    className="fs-4 "
                    onClick={handleCloseTestimonialVideo}
                  />
                </div>
                <div className="pb-4 pt-5">
                  <h2 className="text-center text-secondary-light pb-4 ">
                    {testimonialVideo.heading}
                  </h2>
                  <LazyLoad height={700} offset={100}>
                    <ReactPlayer
                      playing
                      controls
                      className="w-100 h-auto"
                      url={testimonialVideo.video}
                    />
                  </LazyLoad>
                </div>
                <div className="text-center">
                  <Button
                    onClick={handleCloseTestimonialVideo}
                    // onClick={showModal}
                    variant="outline-secondary-light"
                    className="w-100 w-md-auto px-6 text-center  py-2 secondary-light-link "
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {homePageBannerSection &&
            !checkPropertiesForNull(homePageBannerSection, ["heading"]) && (
              <Hero
                title={
                  <SafeHtmlParser
                    htmlContent={homePageBannerSection?.heading}
                  />
                }
                description={
                  <SafeHtmlParser
                    htmlContent={homePageBannerSection?.bannerDescription}
                  />
                }
                img={
                  homePageBannerSection.bannerImage?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={homePageBannerSection.bannerImage?.altText}
                video={
                  homePageBannerSection?.bannerVideo.mediaItemUrl ? true : false
                }
                handleShow={() =>
                  handleShowTestimonialVideo(
                    homePageBannerSection?.videoPopupHeading,
                    homePageBannerSection.bannerVideo?.mediaItemUrl
                  )
                }
                ctaButton={homePageBannerSection?.cta}
                videoButtonLabel={homePageBannerSection?.videoButtonLabel}
              />
            )}
          {homePageTextOnlySection &&
            !checkPropertiesForNull(homePageTextOnlySection, ["heading"]) && (
              <section style={{ overflow: "hidden" }}>
                <BgImage
                  className="pb-5 pb-lg-9 position-relative bg-secondary pt-5 pt-lg-10 bg-image"
                  image={
                    homePageTextOnlySection?.backgroundImage?.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={homePageTextOnlySection?.backgroundImage?.altText}
                  style={{
                    backgroundSize: "70%",
                  }}
                >
                  {/* <div
                    style={{ top: "-4rem", right: "-2rem" }}
                    className="position-absolute d-none d-xl-block text-end w-100"
                  >
                    <img alt="" src={Background1} className="w-60"></img>
                  </div>
                  <div
                    style={{ top: "-2rem" }}
                    className="position-absolute start-0 text-end d-xl-none h-100 w-100"
                  >
                    <img alt="" src={Background1} className="w-100"></img>
                  </div> */}
                  <Container
                    style={{ zIndex: 2 }}
                    className="position-relative pt-lg-4"
                  >
                    <Row className="justify-content-center">
                      <Col lg={8} className="text-center">
                        <h2 className="pb-5 text-white display-5">
                          {homePageTextOnlySection?.heading}
                        </h2>
                        <div className="text-primary-light display-5">
                          <SafeHtmlParser
                            htmlContent={homePageTextOnlySection?.paragraph}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </BgImage>
              </section>
            )}
          {homePageTestimonialSection &&
            !checkPropertiesForNull(homePageTestimonialSection, [
              "heading",
            ]) && (
              <section className="py-5">
                <Container>
                  <Row>
                    <Col>
                      <h2 className="pb-5 fs-1 text-grey-med text-center">
                        {homePageTestimonialSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="d-lg-none review-carousal">
                    <Col>
                      <div>
                        {homePageTestimonialSection.testimonials &&
                          homePageTestimonialSection.testimonials.length >
                            0 && (
                            <Carousel interval={3000}>
                              {homePageTestimonialSection.testimonials.map(
                                (item) => (
                                  <Carousel.Item key={uuid4()}>
                                    <div className=" px-3">
                                      <div style={{ overflow: "hidden" }}>
                                        <GatsbyImage
                                          className="w-100"
                                          image={
                                            item.testimonialsFields
                                              ?.reviewSection?.image.localFile
                                              .childImageSharp.gatsbyImageData
                                          }
                                          alt={
                                            item.testimonialsFields
                                              ?.reviewSection?.image?.altText
                                          }
                                        />
                                      </div>

                                      <div
                                        style={{
                                          borderRadius: "8px",
                                          top: "-4rem",
                                        }}
                                        className="bg-white mx-auto d-xl-none position-relative w-90 px-4 py-4"
                                      >
                                        <p className=" position-absolute start-0 pt-3 top-0 w-100 pb-0 mb-0 text-center text-black">
                                          <span className="display-2 d-none d-lg-block">
                                            “
                                          </span>
                                          <span
                                            style={{ fontSize: "4rem" }}
                                            className=" d-lg-none"
                                          >
                                            “
                                          </span>
                                        </p>
                                        <div
                                          className="d-md-none"
                                          style={{ minHeight: "210px" }}
                                        >
                                          <div className="mt-5">
                                            <SafeHtmlParser
                                              htmlContent={
                                                item?.testimonialsFields
                                                  .reviewSection.description
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="d-none d-md-block"
                                          style={{ minHeight: "100px" }}
                                        >
                                          <div className="mt-5">
                                            <SafeHtmlParser
                                              htmlContent={
                                                item?.testimonialsFields
                                                  .reviewSection.description
                                              }
                                            />
                                          </div>
                                        </div>
                                        <h3 className="fs-4">{item.title}</h3>
                                        <p>
                                          {
                                            item?.testimonialsFields
                                              .reviewSection.problem
                                          }
                                        </p>
                                        <Button
                                          onClick={() =>
                                            handleShowTestimonialVideo(
                                              item?.testimonialsFields
                                                .reviewSection
                                                .videoModalHeading,
                                              item?.testimonialsFields
                                                .reviewSection.video
                                                ?.mediaItemUrl
                                            )
                                          }
                                          // onClick={showModal}
                                          variant="secondary-light"
                                          className="w-100 text-center  py-3 "
                                        >
                                          <div className="d-flex align-items-center w-100 justify-content-center">
                                            <MdMovie className="me-2 fs-4" />
                                            {
                                              item?.testimonialsFields
                                                .reviewSection.videoButtonText
                                            }
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                  </Carousel.Item>
                                )
                              )}
                            </Carousel>
                          )}
                      </div>
                    </Col>
                  </Row>
                  {homePageTestimonialSection.testimonials &&
                    homePageTestimonialSection.testimonials.length > 0 && (
                      <Row className="g-3 d-none d-lg-flex g-xl-7">
                        {homePageTestimonialSection.testimonials.map((item) => (
                          <Col lg={4} key={uuid4()}>
                            <div style={{ overflow: "hidden" }}>
                              <GatsbyImage
                                className="w-100"
                                image={
                                  item.testimonialsFields?.reviewSection?.image
                                    .localFile.childImageSharp.gatsbyImageData
                                }
                                alt={
                                  item.testimonialsFields?.reviewSection?.image
                                    ?.altText
                                }
                              />
                            </div>
                            <div
                              style={{
                                borderRadius: "8px",
                                top: "-4rem",
                                right: "-3rem",
                              }}
                              className="bg-white d-none d-xl-block position-relative px-4 py-4"
                            >
                              <p className=" position-absolute start-0 pt-3 top-0 w-100 pb-0 mb-0 text-center text-black">
                                <span className="display-2">“</span>
                              </p>
                              {item?.testimonialsFields.reviewSection
                                .description && (
                                <div style={{ minHeight: "150px" }}>
                                  <div className="mt-5">
                                    <SafeHtmlParser
                                      htmlContent={
                                        item?.testimonialsFields.reviewSection
                                          .description
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                              <h3 className="fs-4">{item.title}</h3>
                              <p>
                                {item?.testimonialsFields.reviewSection.problem}
                              </p>

                              <Button
                                onClick={() =>
                                  handleShowTestimonialVideo(
                                    item?.testimonialsFields.reviewSection
                                      .videoModalHeading,
                                    item?.testimonialsFields.reviewSection.video
                                      ?.mediaItemUrl
                                  )
                                }
                                // onClick={showModal}
                                variant="secondary-light"
                                className="w-100 text-center  py-3 "
                              >
                                <div className="d-flex align-items-center w-100 justify-content-center">
                                  <MdMovie className="me-2 fs-4" />
                                  {
                                    item?.testimonialsFields.reviewSection
                                      .videoButtonText
                                  }
                                </div>
                              </Button>
                            </div>
                            <div
                              style={{
                                borderRadius: "8px",
                                top: "-4rem",
                              }}
                              className="bg-white mx-auto d-xl-none position-relative w-90 px-4 py-4"
                            >
                              <p className=" position-absolute start-0 pt-3 top-0 w-100 pb-0 mb-0 text-center text-black">
                                <span className="display-2 d-none d-lg-block">
                                  “
                                </span>
                                <span
                                  style={{ fontSize: "4rem" }}
                                  className=" d-lg-none"
                                >
                                  “
                                </span>
                              </p>
                              <div style={{ minHeight: "210px" }}>
                                <div className="mt-5">
                                  <SafeHtmlParser
                                    htmlContent={
                                      item?.testimonialsFields.reviewSection
                                        .description
                                    }
                                  />
                                </div>
                              </div>
                              <h3 className="fs-4">{item.title}</h3>
                              <p>
                                {item?.testimonialsFields.reviewSection.problem}
                              </p>
                              <Button
                                onClick={() =>
                                  handleShowTestimonialVideo(
                                    item?.testimonialsFields.reviewSection
                                      .videoModalHeading,
                                    item?.testimonialsFields.reviewSection.video
                                      ?.mediaItemUrl
                                  )
                                }
                                // onClick={showModal}
                                variant="secondary-light"
                                className="w-100 text-center  py-3 "
                              >
                                <div className="d-flex align-items-center w-100 justify-content-center">
                                  <MdMovie className="me-2 fs-4" />
                                  {
                                    item?.testimonialsFields.reviewSection
                                      .videoButtonText
                                  }
                                </div>
                              </Button>
                            </div>
                          </Col>
                        ))}

                        <Row>
                          <Col className="text-center ">
                            <div className="d-md-flex align-items-center justify-content-center">
                              <p className="fs-4 me-2 comfortaa-bold pb-0 mb-0">
                                {homePageTestimonialSection?.bottomNote ??
                                  "See more reviews on"}
                              </p>{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  homePageTestimonialSection.googleReviewLink
                                    ?.url
                                }
                              >
                                {/* <img alt="Google Logo" src={GoogleIcon}></img> */}
                                <GatsbyImage
                                  image={
                                    homePageTestimonialSection.googleReviewIcon
                                      ?.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt="Google Logo"
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    )}
                </Container>
              </section>
            )}
          {homePageTwoColumnRightImageSection &&
            !checkPropertiesForNull(homePageTwoColumnRightImageSection, [
              "heading",
            ]) && (
              <section className="pb-5 pt-lg-5 pb-lg-7">
                <Container>
                  <Row className="g-5 g-lg-7 justify-content-between align-items-center">
                    <Col lg={{ span: 5, order: "last" }}>
                      <div style={{ borderRadius: "25px", overflow: "hidden" }}>
                        <GatsbyImage
                          className="w-100"
                          image={
                            homePageTwoColumnRightImageSection.image?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={
                            homePageTwoColumnRightImageSection.image?.altText
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={7} xl={6}>
                      <h2 className="text-primary fs-4">
                        {homePageTwoColumnRightImageSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={
                          homePageTwoColumnRightImageSection?.description
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {homePageWorkTogetherSection &&
            !checkPropertiesForNull(homePageWorkTogetherSection, [
              "heading",
            ]) && (
              <BgImage
                className="py-5 pt-lg-7 pt-xl-9 position-relative bg-secondary bg-right-image"
                image={
                  homePageWorkTogetherSection?.backgroundimage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={homePageWorkTogetherSection?.backgroundimage?.altText}
                style={{
                  backgroundPosition: "left center",
                }}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative text-center "
                >
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <h2 className="pb-5 text-white display-5">
                        {homePageWorkTogetherSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  {homePageWorkTogetherSection.items &&
                    homePageWorkTogetherSection.items.length > 0 && (
                      <Row className="align-items-center g-3 g-xl-5">
                        {homePageWorkTogetherSection.items.map((item, i) => (
                          <Col lg={4} key={uuid4()}>
                            <div
                              style={{
                                background: "rgba(0, 0, 0, 0.32)",
                                borderRadius: "6px",
                              }}
                              className={`${
                                i === 1
                                  ? "pt-xl-5 pb-lg-7 pb-xl-9 px-lg-4 px-xl-6"
                                  : "p-lg-3 p-xl-5"
                              } w-100 h-100 text-white p-3  p-md-5  `}
                            >
                              <GatsbyImage
                                className="mb-4"
                                image={
                                  item.image?.localFile.childImageSharp
                                    ?.gatsbyImageData
                                }
                                alt={item.image?.altText}
                              />

                              <h3 className="pb-3">{item.heading}</h3>
                              {item.description && (
                                <>
                                  <div
                                    className="d-none d-lg-block"
                                    style={{ minHeight: "150px" }}
                                  >
                                    <SafeHtmlParser
                                      htmlContent={item.description}
                                    />
                                  </div>
                                  <div className="d-lg-none">
                                    <SafeHtmlParser
                                      htmlContent={item.description}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}

                  {homePageWorkTogetherSection.cta &&
                    homePageWorkTogetherSection.cta.url && (
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to={homePageWorkTogetherSection.cta.url}
                            variant="primary"
                            className="w-100 w-md-auto py-3 mt-5 text-white px-5"
                            target={
                              homePageWorkTogetherSection.cta.target ?? "_self"
                            }
                          >
                            {homePageWorkTogetherSection.cta.title}
                          </Button>
                        </Col>
                      </Row>
                    )}
                </Container>
              </BgImage>
            )}

          {homePageTwoColumnLeftImageSectionWithSectionTitle &&
            !checkPropertiesForNull(
              homePageTwoColumnLeftImageSectionWithSectionTitle,
              ["sectionTitle"]
            ) && (
              <section className="pt-5 pt-lg-7">
                <Container>
                  <Row>
                    <Col>
                      <h2 className="pb-5 text-grey-med display-5 text-center text-lg-start">
                        {
                          homePageTwoColumnLeftImageSectionWithSectionTitle?.sectionTitle
                        }
                      </h2>
                    </Col>
                  </Row>
                </Container>
                <Container fluid className="px-0 mx-0 pb-4">
                  <Row className="g-0 h-100">
                    <Col style={{ minHeight: "100%" }} lg={6}>
                      <GatsbyImage
                        className="w-100 h-100"
                        image={
                          homePageTwoColumnLeftImageSectionWithSectionTitle
                            .image?.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={
                          homePageTwoColumnLeftImageSectionWithSectionTitle
                            .image?.altText
                        }
                      />
                    </Col>
                    <Col style={{ minHeight: "100%" }}>
                      <div
                        style={{
                          background:
                            "var(--grad-blue, linear-gradient(150deg, #E9FAFF 0%, #C1F1FF 100%))",
                        }}
                        className="w-100 h-100 py-6 px-4 px-lg-0"
                      >
                        <Row className="h-100 align-items-center">
                          <Col
                            lg={{ span: 8, offset: 2 }}
                            xl={{ span: 6, offset: 2 }}
                          >
                            <h3 className="fs-2 mb-4">
                              {
                                homePageTwoColumnLeftImageSectionWithSectionTitle?.heading
                              }
                            </h3>
                            {homePageTwoColumnLeftImageSectionWithSectionTitle?.description && (
                              <SafeHtmlParser
                                htmlContent={
                                  homePageTwoColumnLeftImageSectionWithSectionTitle?.description
                                }
                              />
                            )}
                            {homePageTwoColumnLeftImageSectionWithSectionTitle.cta &&
                              homePageTwoColumnLeftImageSectionWithSectionTitle
                                .cta?.url && (
                                <div className="text-center">
                                  <Button
                                    as={Link}
                                    to={
                                      homePageTwoColumnLeftImageSectionWithSectionTitle
                                        .cta?.url
                                    }
                                    variant="outline-secondary"
                                    className="w-100 w-md-auto py-2 mt-3 px-7 secondary-link"
                                    target={
                                      homePageTwoColumnLeftImageSectionWithSectionTitle
                                        .cta?.target ?? "_self"
                                    }
                                  >
                                    {
                                      homePageTwoColumnLeftImageSectionWithSectionTitle
                                        .cta?.title
                                    }
                                  </Button>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
                {homePageTwoColumnRightImageSectionWithCta &&
                  !checkPropertiesForNull(
                    homePageTwoColumnRightImageSectionWithCta,
                    ["heading"]
                  ) && (
                    <Container fluid className="px-0 mx-0 pb-4">
                      <Row className="g-0 h-100">
                        <Col
                          style={{ minHeight: "100%" }}
                          lg={{ span: 6, order: "last" }}
                        >
                          <GatsbyImage
                            className="w-100 h-100"
                            image={
                              homePageTwoColumnRightImageSectionWithCta.image
                                ?.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={
                              homePageTwoColumnRightImageSectionWithCta.image
                                ?.altText
                            }
                          />
                        </Col>
                        <Col style={{ minHeight: "100%" }}>
                          <div
                            style={{
                              background:
                                "var(--grad-red, linear-gradient(212deg, #FFE9E8 0%, #FFC6C2 100%))",
                            }}
                            className="w-100 h-100 py-6 px-4 px-lg-0"
                          >
                            <Row className="h-100 align-items-center">
                              <Col
                                lg={{ span: 8, offset: 2 }}
                                xl={{ span: 6, offset: 5 }}
                              >
                                <h3 className="fs-2 mb-4">
                                  {
                                    homePageTwoColumnRightImageSectionWithCta?.heading
                                  }
                                </h3>
                                {homePageTwoColumnRightImageSectionWithCta?.description && (
                                  <SafeHtmlParser
                                    htmlContent={
                                      homePageTwoColumnRightImageSectionWithCta?.description
                                    }
                                  />
                                )}
                                {homePageTwoColumnRightImageSectionWithCta?.cta &&
                                  homePageTwoColumnRightImageSectionWithCta?.cta
                                    .url && (
                                    <div className="text-center">
                                      <Button
                                        as={Link}
                                        to={
                                          homePageTwoColumnRightImageSectionWithCta
                                            ?.cta?.url
                                        }
                                        variant="outline-primary-dark"
                                        className="w-100 w-md-auto py-2 mt-3 px-7 primary-dark-link"
                                        target={
                                          homePageTwoColumnRightImageSectionWithCta
                                            ?.cta?.target ?? "_self"
                                        }
                                      >
                                        {
                                          homePageTwoColumnRightImageSectionWithCta
                                            ?.cta.title
                                        }
                                      </Button>
                                    </div>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
                {homePageTwoColumnLeftImageSectionWithCta &&
                  !checkPropertiesForNull(
                    homePageTwoColumnLeftImageSectionWithCta,
                    ["heading"]
                  ) && (
                    <Container fluid className="px-0 mx-0 pb-4">
                      <Row className="g-0 h-100">
                        <Col style={{ minHeight: "100%" }} lg={6}>
                          <GatsbyImage
                            className="w-100 h-100"
                            image={
                              homePageTwoColumnLeftImageSectionWithCta.image
                                ?.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={
                              homePageTwoColumnLeftImageSectionWithCta.image
                                ?.altText
                            }
                          />
                        </Col>
                        <Col style={{ minHeight: "100%" }}>
                          <div
                            style={{
                              background:
                                "var(--grad-blue, linear-gradient(150deg, #E9FAFF 0%, #C1F1FF 100%))",
                            }}
                            className="w-100 h-100 py-6 px-4 px-lg-0"
                          >
                            <Row className="h-100 align-items-center">
                              <Col
                                lg={{ span: 8, offset: 2 }}
                                xl={{ span: 6, offset: 2 }}
                              >
                                <h3 className="fs-2 mb-4">
                                  {
                                    homePageTwoColumnLeftImageSectionWithCta?.title
                                  }
                                </h3>
                                {homePageTwoColumnLeftImageSectionWithCta.description && (
                                  <SafeHtmlParser
                                    htmlContent={
                                      homePageTwoColumnLeftImageSectionWithCta?.description
                                    }
                                  />
                                )}

                                {homePageTwoColumnLeftImageSectionWithCta?.cta &&
                                  homePageTwoColumnLeftImageSectionWithCta?.cta
                                    .url && (
                                    <div className="text-center">
                                      <Button
                                        as={Link}
                                        to={
                                          homePageTwoColumnLeftImageSectionWithCta
                                            ?.cta.url
                                        }
                                        variant="outline-secondary"
                                        className="w-100 w-md-auto py-2 mt-3 px-7 secondary-link"
                                        target={
                                          homePageTwoColumnLeftImageSectionWithCta
                                            ?.cta?.target ?? "_self"
                                        }
                                      >
                                        {
                                          homePageTwoColumnLeftImageSectionWithCta
                                            ?.cta.title
                                        }
                                      </Button>
                                    </div>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
              </section>
            )}
          {homePageFaqSection &&
            !checkPropertiesForNull(homePageFaqSection, ["faq"]) && (
              <BgImage
                className="py-5 pt-lg-7 pt-xl-9 position-relative bg-secondary bg-right-image"
                image={
                  homePageFaqSection?.backgroundImage?.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={homePageFaqSection?.backgroundImage?.altText}
                style={{
                  backgroundPosition: "left center",
                }}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative text-center "
                >
                  <Faq
                    heading={homePageFaqSection?.heading}
                    faqs={homePageFaqSection?.faq}
                  />
                </Container>
              </BgImage>
            )}

          {homePageStartYourJourneySection &&
            !checkPropertiesForNull(homePageStartYourJourneySection, [
              "heading",
            ]) && (
              <BgImage
                className="py-5 py-lg-7 py-xl-10 position-relative"
                image={
                  homePageStartYourJourneySection?.backgroundImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={homePageStartYourJourneySection?.backgroundImage?.altText}
              >
                <section style={{ overflow: "hidden" }}>
                  <Container
                    style={{ zIndex: 3 }}
                    className="mt-xl-10 position-relative"
                  >
                    <Row>
                      <Col className="text-center">
                        <h2 className="pb-5 display-3 d-none d-lg-block">
                          <SafeHtmlParser
                            htmlContent={
                              homePageStartYourJourneySection?.heading
                            }
                          />
                        </h2>
                        <h2 className="pb-5  display-5 d-lg-none">
                          <SafeHtmlParser
                            htmlContent={
                              homePageStartYourJourneySection?.heading
                            }
                          />
                        </h2>
                        {homePageStartYourJourneySection.cta &&
                          homePageStartYourJourneySection.cta?.url && (
                            <Button
                              as={Link}
                              to={homePageStartYourJourneySection.cta?.url}
                              variant="primary"
                              className="w-100 w-md-auto py-3  text-white px-5"
                              target={
                                homePageStartYourJourneySection.cta?.target ??
                                "_self"
                              }
                            >
                              {homePageStartYourJourneySection.cta?.title}
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              </BgImage>
            )}
        </Layout>
      </div>
    </>
  );
};

export default IndexPage;
